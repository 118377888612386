import React from "react";
import PropTypes from "prop-types";

import { Icon, ImagePreview } from "@rdcs/dap-front-library";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const Content = ({ medical, accessRight, enableMedical, refuseMedical }) => (
    <div className="moderationListItem recommendationsPatientListItem">
        <ImagePreview className="moderationListItem__visuel" filename={medical.avatar && medical.avatar.filename} fallbackImageUrl={defaultUserImage} />
        <div className="moderationListItem__infos">
            <div className="moderationListItem__name">
                {medical.firstName} {medical.lastName}
            </div>
            <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">{medical.medicalRole}</span>
            </div>
            <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                    {medical.address}, {medical.zipcode} {medical.city}
                </span>
            </div>
            {medical.phoneNumber1 && (
                <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                    <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                        {medical.phoneNumber1?.replace(/(\d)(?=(\d\d)+(?!\d))/g, "$1.")}
                    </span>
                </div>
            )}
        </div>
        {!accessRight.enable && (
            <div className="moderationListItem__actions-content">
                <button className="moderationListItem__action" onClick={() => enableMedical(accessRight)}>
                    <Icon name="check-circle" className="moderationListItem__action-icon" />
                    Accepter le praticien
                </button>
                <button className="moderationListItem__action" onClick={() => refuseMedical(accessRight)}>
                    <Icon name="cross-circle" className="moderationListItem__action-icon" />
                    Refuser le praticien
                </button>
            </div>
        )}
    </div>
);

Content.propTypes = {
    medical: PropTypes.object.isRequired,
    accessRight: PropTypes.object.isRequired,
    enableMedical: PropTypes.func,
    refuseMedical: PropTypes.func,
};

export default Content;
