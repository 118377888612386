import moment from "moment";

export const LOGIN_URL =
    window.env.AUTH_URL +
    "/oidc/authorize?apiKey=" +
    window.env.AUTH_API_KEY +
    "&client_id=" +
    window.env.AUTH_CLIENT_ID +
    "&redirect_uri=" +
    window.env.AUTH_REDIRECT_URI +
    "&lang=fr_FR";

export const getConsultDateFromRecurrence = (diagnosisDate, recommendationRecurrence) => {
    const baseDate = moment(diagnosisDate);
    
    switch (true) {
        case recommendationRecurrence === "none":
            return baseDate.add(1, "year");
        case recommendationRecurrence === "every_two_years":
            return baseDate.add(2, "years");
        case recommendationRecurrence === "annually":
            return baseDate.add(1, "year");
        case recommendationRecurrence === "every_six_months":
            return baseDate.add(6, "months");
        case recommendationRecurrence === "quarterly":
            return baseDate.add(3, "months");
        case recommendationRecurrence === "monthly":
            return baseDate.add(1, "months");
        case recommendationRecurrence === "weekly":
            return baseDate.add(7, "days");
        case recommendationRecurrence === "daily":
            return baseDate.add(1, "day");
        default:
            console.error("Unknown recommendation recurrence");
            return baseDate;
    }
}

export const isLocalAPI = process.env.REACT_APP_USE_LOCAL_API === "true";
