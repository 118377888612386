import React from 'react';

const Content = () => (
    <div className="txtCenter">
        <h2 className="txtSizeExtraBig txtBold mt-3">404</h2>
        <p className="mt-2">
            La page demandée n'existe pas ou le contenu est indisponible
        </p>
    </div>
);

Content.propTypes = {};

export default Content;