import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryGigyaRetrieveTokenFromCode = (code) => dispatch => {
    dispatch({ type: types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED });
    return API.request({
        method: "POST",
        path: "/ciam/api/v1/access/token",
        data: {
            apiKey: window.env.AUTH_API_KEY,
            redirectUri: window.env.AUTH_REDIRECT_URI,
            tokenType: "CODE",
            token: code
        }
    })
    .then(authData => {
        dispatch({ type: types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS, data: authData });
        return authData;
    })
    .catch(error => {
        dispatch({ type: types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE, data: error });
        throw error;
    })
    .then(authData => {
        dispatch({ type: types.USERINFO_REQUESTED });
        return API.request({
            method: "GET",
            path: isLocalAPI ? "/userinfo" : "/dap-hcp-patient/api/v1/customers/userinfo",
            token: authData.access_token
        })
        .then(user => {
            dispatch({ type: types.USERINFO_SUCCESS, data: user });
            return user;
        })
        .catch(() => {
            dispatch({ type: types.USERINFO_FAILURE });
            throw new Error('Failed to retrieve user info.');
        })
    });
}

export const tryGigyaRefreshToken = (refreshToken) => dispatch => {
    dispatch({ type: types.GIGYA_REFRESH_TOKEN_REQUESTED });
    return API.request({
        method: "POST",
        path: "/ciam/api/v1/access/token",
        data: {
            apiKey: window.env.AUTH_API_KEY,
            redirectUri: window.env.AUTH_REDIRECT_URI,
            tokenType: "REFRESH",
            token: refreshToken
        }
    })
    .then(response => dispatch({ type: types.GIGYA_REFRESH_TOKEN_SUCCESS, data: response }))
    .catch(error => {
        dispatch({ type: types.GIGYA_REFRESH_TOKEN_FAILURE });
        dispatch(tryLogout());
        throw error;
    });
};

export const tryLogout = () => dispatch => {
    dispatch({ type: types.LOGOUT_SUCCESS });
};

export const tryGigyaNewPassword = (token, password, confirmPassword) => dispatch => {
    dispatch({ type: types.GIGYA_NEW_PASSWORD_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/gigya/new-password" : "/dap-hcp-patient/api/v1/customers/patients/new-password",
        method: "POST",
        data: {
            token: token,
            password: password,
            confirm_password: confirmPassword
        }
    })
    .then(response => dispatch({ type: types.GIGYA_NEW_PASSWORD_SUCCESS, data: response }))
    .catch(error => dispatch({ type: types.GIGYA_NEW_PASSWORD_FAILURE, data: error }));
};
