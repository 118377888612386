import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import { Icon, PatientLayoutAnonymous, PatientLayoutAsideAnonymous, PageTitle } from "@rdcs/dap-front-library";

import AppLayout from "../../../components/Layout/AppLayout";

import Content from "./Content";

const NotFoundExceptionScreen = ({ user }) => (
    <PageTitle title="Timkl - Page non trouvée">
        {user ? (
            <AppLayout navActive={null}>
                <Content />
            </AppLayout>
        ) : (
            <PatientLayoutAnonymous>
                <PatientLayoutAsideAnonymous />
                <main className="credentialsMain__container">
                    <div>
                        <Link to="/connexion" className="credentialsMain__back">
                            <Icon name="arrow-left" />
                        </Link>
                        <Content />
                    </div>
                </main>
            </PatientLayoutAnonymous>
        )}
    </PageTitle>
);

NotFoundExceptionScreen.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(NotFoundExceptionScreen);
