import * as types from "./types";

import store from '../store';

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryFetchRecommandations = () => dispatch => {
    const {
        auth: {
            access_token,
            user: {
                id
            }
        },
        recommandations: {
            recos,
            recosPatientId,
            recosDiagnosticId,
            recosLoaded
        }
    } = store.getState();

    if (recosPatientId === id && recosLoaded) {
        return Promise.resolve(recos);
    }

    dispatch({ type: types.RECOMMANDATIONS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics",
        queryParams: {
            itemsPerPage: 1,
            completed: 1,
            "order[createdAt]": "desc",
            patient: id
        },
        method: "GET",
        token: access_token
    })
    .then(response => {
        const diagnosisId = response["hydra:member"][0]?.["@id"] || null;

        if (recosDiagnosticId === diagnosisId) {
            return { diagnosisId, recommandations: recos };
        }

        return API.request({
            path: isLocalAPI
                ? "/diagnostic_recommendations"
                : "/dap-hcp-patient/api/v1/clinical-data/diagnostics-recommendations",
            queryParams: {
                active: 1,
                diagnostic: diagnosisId,
                pagination: false
            },
            token: access_token
        })
        .then(response => ({ diagnosisId, recommandations: response['hydra:member'] }));
    })
    .then(({ diagnosisId, recommandations }) => {
        dispatch({ type: types.RECOMMANDATIONS_FETCH_SUCCESS, data: recommandations, diagnosisId, patientId: id })
    })
    .catch(() => dispatch({ type: types.RECOMMANDATIONS_FETCH_FAILURE }));
};
