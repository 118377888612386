import * as types from "./types";

import store from '../store';

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryFetchLastDiagnosis = () => dispatch => {
    const {
        auth: {
            access_token,
            user: {
                id: patientId
            }
        },
        diagnosis: {
            patientLastDiagnosis,
            patientLastDiagnosisPatientId,
            patientLastDiagnosisLoaded 
        }
    } = store.getState();

    if (patientLastDiagnosisLoaded && patientLastDiagnosisPatientId === patientId) {
        return Promise.resolve(patientLastDiagnosis);
    }
    dispatch({ type: types.PATIENT_LAST_DIAGNOSIS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics",
        queryParams: {
            itemsPerPage: 1,
            completed: 1,
            "order[createdAt]": "desc",
            patient: patientId
        },
        method: "GET",
        token: access_token
    })
    .then(response => {
        const patientLastDiagnosisResponse = response["hydra:member"][0] || null;

        dispatch({
            type: types.PATIENT_LAST_DIAGNOSIS_SUCCESS,
            patientLastDiagnosis: patientLastDiagnosisResponse,
            patientId
        });
        return patientLastDiagnosisResponse;
    })
    .catch(() => dispatch({ type: types.PATIENT_LAST_DIAGNOSIS_FAILURE }));
};
