import * as types from "../actions/types";

const initialState = {
    patientLastDiagnosis: null,
    patientLastDiagnosisLoading: false,
    patientLastDiagnosisPatientId: -1,
    patientLastDiagnosisLoaded: 0,
};

function diagnosis(state = initialState, action) {
    switch (action.type) {
        // FETCH PATIENT LAST DIAGNOSIS
        case types.PATIENT_LAST_DIAGNOSIS_REQUESTED: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: true,
                patientLastDiagnosis: null,
                patientLastDiagnosisPatientId: -1,
                patientLastDiagnosisLoaded: 0,
            });
        }
        case types.PATIENT_LAST_DIAGNOSIS_SUCCESS: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: false,
                patientLastDiagnosis: action.patientLastDiagnosis,
                patientLastDiagnosisPatientId: action.patientId,
                patientLastDiagnosisLoaded: Date.now(),
            });
        }
        case types.PATIENT_LAST_DIAGNOSIS_FAILURE: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: false,
            });
        }
        default:
            return state;
    }
}

export default diagnosis;
