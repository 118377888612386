import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryFetchRecommandations } from "../../actions/recommandations";
import { tryPatientAppointments } from "../../actions/calendar";

import { DefaultLoader, HorizontalNavbarTab, Icon, AppointmentRealised, PageTitle, useLoadAfterPromises } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";
import navBar from "./navBar";

const breadcrumbs = [{ label: "Mes recommandations réalisées" }];

const DoneRecommandationsScreen = ({ user, calendar, recos, tryFetchRecommandations, tryPatientAppointments }) => {
    const [loadingRequestReco, loadingRequestAppointments] = React.useMemo(() => [[tryFetchRecommandations], [tryPatientAppointments]], []);
    const loadingReco = useLoadAfterPromises(loadingRequestReco);
    const loadingAppointments = useLoadAfterPromises(loadingRequestAppointments);

    const recosInfo = React.useMemo(
        () => recos.filter((r) => r.recommendation.type === "information").sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
        [recos]
    );

    const appointmentsDone = React.useMemo(() => calendar.filter((item) => item.doneAt !== null).sort((a, b) => (a.dueAt > b.dueAt ? -1 : 1)), [calendar]);

    return (
        <PageTitle title="Timkl - Recommandations">
            <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
                <div className="recommendationsPatientHeader">
                    <div className="recommendationsPatientHeader__contentHolder">
                        <p className="recommendationsPatientHeader__emoji">
                            <span role="img" aria-label="WaveHandEmoji">
                                👋
                            </span>
                        </p>
                        <p className="recommendationsPatientHeader__title">Bonjour {user.firstName}</p>
                        <div className="informationsList mt-3">
                            {loadingReco ? (
                                <DefaultLoader color="#CCC" />
                            ) : (
                                recosInfo.map((reco) => (
                                    <div className="informationsList__item mt-2" key={reco.id}>
                                        <span className="informationsList__item-sourceLink">
                                            <Icon name="puce-reco" />
                                        </span>
                                        {reco.recommendation.title}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <HorizontalNavbarTab navbar={navBar} tabActive={1} />
                <div>
                    {loadingAppointments ? (
                        <DefaultLoader color="#CCC" />
                    ) : appointmentsDone.length > 0 ? (
                        appointmentsDone.map((appointment) => <AppointmentRealised key={appointment.id} appointment={appointment} />)
                    ) : (
                        <div>Aucune recommandation réalisée</div>
                    )}
                </div>
            </AppLayout>
        </PageTitle>
    );
};

DoneRecommandationsScreen.propTypes = {
    user: PropTypes.object.isRequired,
    calendar: PropTypes.arrayOf(PropTypes.object).isRequired,
    recos: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchRecommandations: PropTypes.func.isRequired,
    tryPatientAppointments: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { user }, calendar: { calendar }, recommandations: { recos } }) => ({ user, calendar, recos });

const mapDispatchtoProps = {
    tryFetchRecommandations,
    tryPatientAppointments,
};

export default connect(mapStateToProps, mapDispatchtoProps)(DoneRecommandationsScreen);
