import React from 'react';

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_TYPE_ADD_ITEM':
            return {
                ...prevState,
                addItem: {
                    ...prevState.addItem,
                    type: action.measureType
                }
            }
        case 'SET_DATE_ADD_ITEM':
            return {
                ...prevState,
                addItem: {
                    ...prevState.addItem,
                    date: action.date
                }
            }
        case 'SET_VALUE_ADD_ITEM':
            return {
                ...prevState,
                addItem: {
                    ...prevState.addItem,
                    value: action.value
                }
            }
        case 'TOGGLE_SHOW_ADD_ITEM':
            return {
                ...prevState,
                showAddItem: !prevState.showAddItem
            }
        case 'EXTENDS_ITEMS_TO_SHOW':
            return {
                ...prevState,
                itemsToShow: prevState.itemsToShow + 5
            }
        default:
            return prevState;
    }
};

const useState = (userId) => {
    const defaultState = React.useMemo(() => ({
        itemsToShow: 5,
        showAddItem: false,
        addItem: {
            date: '',
            val: 0,
            patient: `/patients/${userId}`,
            source: "DAP",
        }
    }), [userId]);
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setTypeAddItem: (measureTypes) => (
            dispatch({
                type: 'SET_TYPE_ADD_ITEM',
                measureType: measureTypes.find(({ name }) => name === "HbA1c")?.["@id"]
            })
        ),

        setDateAddItem: (date) => dispatch({ type: 'SET_DATE_ADD_ITEM', date }),

        setValueAddItem: (value) => dispatch({ type: 'SET_VALUE_ADD_ITEM', value }),

        toggleShowAddItem: () => dispatch({ type: 'TOGGLE_SHOW_ADD_ITEM' }),

        extendsItemsToShow: () => dispatch({ type: 'EXTENDS_ITEMS_TO_SHOW' }),

    }), []);

    return [state, methods];
};

export default useState;