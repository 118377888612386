import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { Animated } from "react-animated-css";

import Content from './Content';

const MedicalItem = ({ medical, accessRight, enableMedical, refuseMedical }) => (
    <Animated animationIn="fadeIn">
        {accessRight.enable ? (
            <Link to={"/mes-soignants/" + medical.id + "/details"}>
                <Content
                    medical={medical}
                    accessRight={accessRight}
                    enableMedical={enableMedical}
                    refuseMedical={refuseMedical}
                />
            </Link>
        ) : (
            <Content
                medical={medical}
                accessRight={accessRight}
                enableMedical={enableMedical}
                refuseMedical={refuseMedical}
            />
        )}
    </Animated>
);

MedicalItem.propTypes = {
    medical: PropTypes.object.isRequired,
    accessRight: PropTypes.object.isRequired,
    enableMedical: PropTypes.func,
    refuseMedical: PropTypes.func,
};

export default React.memo(MedicalItem);
