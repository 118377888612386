import store from '../store';

import API from "./API";
import { isLocalAPI } from "./utils";

const fetchHealthProfessionals = (filter = null) => (
    API.request({
        path: isLocalAPI ? "/practitioners" : "/dap-hcp-patient/api/v1/customers/practitioners",
        queryParams: {
            discr: filter.type,
            city: filter.city,
            fullName: filter.fullName,
            page: filter.page,
            itemsPerPage: filter.itemsPerPage
        },
        token: store.getState().auth.access_token
    })
);

export default fetchHealthProfessionals;