import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Animated } from "react-animated-css";
import { toast } from "react-toastify";

import { HorizontalNavbarTab, Button, PageTitle } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";
import API from "../../utils/API";
import { isLocalAPI } from "../../utils/utils";
import navBar from "./navBar";

const ExportProfileScreen = ({ access_token }) => {
    const [personalDataLoading, setPersonalDataLoading] = React.useState(false);

    const getPersonalData = (e) => {
        e.preventDefault();

        if (!personalDataLoading) {
            setPersonalDataLoading(true);
            API.request({
                path: isLocalAPI ? "/export-personal-data" : "/dap-hcp-patient/api/v1/customers/export-personal-data",
                token: access_token,
            })
                .then(() => {
                    toast.success("Un lien de téléchargement de vos données personnelles vous a été envoyé par email", {
                        autoClose: 3000,
                    });
                })
                .catch(() => {
                    toast.error("Vos données personelles n'ont pas pu être exportées", {
                        autoClose: 3000,
                    });
                })
                .finally(() => {
                    setPersonalDataLoading(false);
                });
        }
    };

    return (
        <PageTitle title="Timkl - Export de données">
            <AppLayout navActive={null}>
                <HorizontalNavbarTab navbar={navBar} tabActive={1} />
                <Animated animationIn="fadeIn">
                    <h2 className="title-2">Export de mes données personnelles</h2>
                    <br />
                    <Button className="button__primaryButton" onClick={getPersonalData} isLoading={personalDataLoading}>
                        Télécharger mes données personelles
                    </Button>
                </Animated>
            </AppLayout>
        </PageTitle>
    );
};

ExportProfileScreen.propTypes = {
    access_token: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth: { access_token } }) => ({ access_token });

export default connect(mapStateToProps)(ExportProfileScreen);
