import * as types from "./types";

import store from '../store';

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const trySearchMedical = (searchParams) => dispatch => {
    const { access_token } = store.getState().auth;

    dispatch({ type: types.SEARCH_MEDICAL_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/practitioners" : "/dap-hcp-patient/api/v1/customers/practitioners",
        queryParams: searchParams,
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.SEARCH_MEDICAL_SUCCESS, data: response["hydra:member"] });
        return response['hydra:member'];
    })
    .catch(() => dispatch({ type: types.SEARCH_MEDICAL_FAILURE }));
};

export const tryPatientMedical = () => dispatch => {
    const {
        auth: {
            access_token
        },
        medicals: {
            patientMedicals,
            patientMedicalsLoaded
        }
    } = store.getState();

    if (patientMedicalsLoaded) {
        return Promise.resolve(patientMedicals);
    }

    dispatch({ type: types.PATIENT_MEDICAL_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights" : "/dap-hcp-patient/api/v1/ciam/access/rights",
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.PATIENT_MEDICAL_SUCCESS, data: response["hydra:member"] });
        return response["hydra:member"];
    })
    .catch(error => {
        dispatch({ type: types.PATIENT_MEDICAL_FAILURE });
        throw error;
    });
};

export const tryPatientMedicalDelete = accessRight => dispatch => {
    const { access_token } = store.getState().auth;

    console.log(accessRight)
    dispatch({ type: types.PATIENT_MEDICAL_DELETE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights/{id}" : "/dap-hcp-patient/api/v1/ciam/access/rights/{id}",
        uriParams: { id: accessRight.id },
        method: "DELETE",
        token: access_token
    })
    .then(() => dispatch({ type: types.PATIENT_MEDICAL_DELETE_SUCCESS, data: accessRight["@id"] }))
    .catch(() => dispatch({ type: types.PATIENT_MEDICAL_DELETE_FAILURE }));
};

export const tryPatientMedicalCreate = (medical) => dispatch => {
    const {
        access_token,
        user: {
            id
        }
    } = store.getState().auth;

    dispatch({ type: types.PATIENT_MEDICAL_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights" : "/dap-hcp-patient/api/v1/ciam/access/rights",
        method: "POST",
        token: access_token,
        data: {
            patient: "/patients/" + id,
            user: medical["@id"],
            rights: "rw"
        }
    })
    .then(response => {
        dispatch({ type: types.PATIENT_MEDICAL_CREATE_SUCCESS, data: response });
        return response;
    })
    .catch(() => dispatch({ type: types.PATIENT_MEDICAL_CREATE_FAILURE }));
};

export const tryPatientMedicalEnable = (accessRight) => dispatch => {
    const { access_token } = store.getState().auth;

    dispatch({ type: types.PATIENT_MEDICAL_ENABLE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights/{id}" : "/dap-hcp-patient/api/v1/ciam/access/rights/{id}",
        uriParams: { id: accessRight.id },
        method: "PUT",
        token: access_token,
        data: { enable: true }
    })
    .then(response => {
        dispatch({ type: types.PATIENT_MEDICAL_ENABLE_SUCCESS, data: response });
        return response;
    })
    .catch(() => dispatch({ type: types.PATIENT_MEDICAL_ENABLE_FAILURE }));
};
