import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    measures: [],
    measuresLoading: false,
    measuresCurrentPatientId: -1,
    measureLoaded: 0,

    measureTypes: [],
    measureTypesLoading: false,
    measureTypesLoaded: 0,

    created: false,
    createError: false,
    createLoading: false
};

function data(state = initialState, action) {
    switch (action.type) {
        // FETCH MEASURES
        case types.PATIENT_DATA_REQUESTED: {
            return Object.assign({}, state, {
                measuresLoading: true,
                measuresCurrentPatientId: -1,
                measureLoaded: 0,
            });
        }
        case types.PATIENT_DATA_SUCCESS: {
            return Object.assign({}, state, {
                measuresLoading: false,
                measures: action.data,
                measuresCurrentPatientId: action.patientId,
                measureLoaded: Date.now(),
            });
        }
        case types.PATIENT_DATA_FAILURE: {
            return Object.assign({}, state, {
                measuresLoading: false,
                measures: [],
                measuresCurrentPatientId: -1,
                measureLoaded: 0,
            });
        }
        // CREATE
        case types.PATIENT_DATA_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                created: false
            });
        }
        case types.PATIENT_DATA_CREATE_SUCCESS: {
            toast.success("La donnée a bien été créée", { autoClose: 5000 });
            return Object.assign({}, state, {
                createLoading: false,
                createError: false,
                created: action.data,
                measures: [action.data, ...state.measures]
            });
        }
        case types.PATIENT_DATA_CREATE_FAILURE: {
            toast.error("La donnée n'a pas pu être créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
                created: false
            });
        }
        // FETCH MEASURES TYPES
        case types.PATIENT_DATA_TYPES_REQUESTED: {
            return Object.assign({}, state, {
                measureTypesLoading: true,
                measureTypesLoaded: 0
            });
        }
        case types.PATIENT_DATA_TYPES_SUCCESS: {
            return Object.assign({}, state, {
                measureTypesLoading: false,
                measureTypes: action.data,
                measureTypesLoaded: Date.now(),
            });
        }
        case types.PATIENT_DATA_TYPES_FAILURE: {
            return Object.assign({}, state, {
                measureTypesLoading: false,
                measureTypes: [],
                measureTypesLoaded: 0,
            });
        }
        default:
            return state;
    }
}

export default data;
