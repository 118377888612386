import * as types from "./types";

import store from '../store';

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryFetchPatientMeasures = () => dispatch => {
    const {
        auth: {
            access_token,
            user: {
                id: patientId
            }
        },
        measures: {
            measureLoaded,
            measuresCurrentPatientId,
            measures
        }
    } = store.getState();

    if (measureLoaded && measuresCurrentPatientId === patientId) {
        return Promise.resolve(measures);
    }

    dispatch({ type: types.PATIENT_DATA_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measures" : "/dap-hcp-patient/api/v1/clinical-data/measures",
        queryParams: {
            "order[date]": "asc",
            pagination: false,
            "patient.id": patientId
        },
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.PATIENT_DATA_SUCCESS, data: response["hydra:member"], patientId: patientId });
        return response;
    })
    .catch(() => dispatch({ type: types.PATIENT_DATA_FAILURE }));
};

export const tryCreatePatientMeasures = (data) => dispatch => {
    const { access_token } = store.getState().auth;

    dispatch({ type: types.PATIENT_DATA_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measures" : "/dap-hcp-patient/api/v1/clinical-data/measures",
        method: "POST",
        token: access_token,
        data: data
    })
    .then(response => {
        dispatch({ type: types.PATIENT_DATA_CREATE_SUCCESS, data: response });
        return response;
    })
    .catch(error => dispatch({ type: types.PATIENT_DATA_CREATE_FAILURE, data: error }));
};

export const tryFetchMeasureTypes = () => dispatch => {
    const {
        auth: {
            access_token
        },
        measures: {
            measureTypesLoaded,
            measureTypes
        }
    } = store.getState();

    if (measureTypesLoaded) {
        return Promise.resolve(measureTypes);
    }

    dispatch({ type: types.PATIENT_DATA_TYPES_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measure_types" : "/dap-hcp-patient/api/v1/clinical-data/measure-types",
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.PATIENT_DATA_TYPES_SUCCESS, data: response["hydra:member"] });
        return response["hydra:member"];
    })
    .catch(error => dispatch({ type: types.PATIENT_DATA_TYPES_FAILURE, data: error }));
};
