import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import { PatientLayoutAnonymous, PatientLayoutAsideAnonymous, redirectUrlAfterAuthPath, PageTitle } from "@rdcs/dap-front-library";

import { LOGIN_URL } from "../../utils/utils";

const LoginScreen = ({ user }) => {
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        if (user) {
            history.push(redirectUrlAfterAuthPath(location.patientFinalized ? "/mes-soignants/demandes" : "/mes-recommandations"));
        }
    }, []);

    return (
        !user && (
            <PageTitle title="Timkl - Connexion">
                <PatientLayoutAnonymous>
                    <div className="grid credentials__container">
                        <PatientLayoutAsideAnonymous />
                        <main className="col-sm-50 credentialsMain__container">
                            <div className="credentialsMain">
                                <div className="credentials__title">
                                    Connectez-vous à <br />
                                    Diabète Adhérence Plateforme
                                </div>
                                <div>
                                    <a href={LOGIN_URL} className="button button__primaryButton mx-0">
                                        S'identifier
                                    </a>
                                </div>
                            </div>
                            <div className="credentialsFooter txtUnderline">
                                <Link to="/cgu">CGU</Link>
                            </div>
                        </main>
                    </div>
                </PatientLayoutAnonymous>
            </PageTitle>
        )
    );
};

LoginScreen.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(LoginScreen);
