import * as types from "./types";

import store from '../store';

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryPatientAppointments = () => dispatch => {
    const {
        auth: {
            access_token
        },
        calendar: {
            calendarLoaded,
            calendar
        }
    } = store.getState();

    if (calendarLoaded) {
        return Promise.resolve(calendar);
    }

    dispatch({ type: types.PATIENT_APPOINTMENTS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments" : "/dap-hcp-patient/api/v1/customers/patients/appointments",
        method: "GET",
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.PATIENT_APPOINTMENTS_SUCCESS, data: response["hydra:member"] });
        return response["hydra:member"];
    })
    .catch(() => dispatch({ type: types.PATIENT_APPOINTMENTS_FAILURE }));
};

export const tryDeletePatientAppointment = (id) => dispatch => {
    const { access_token } = store.getState().auth;

    dispatch({ type: types.DELETE_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments/{id}" : "/dap-hcp-patient/api/v1/customers/patients/appointments/{id}",
        uriParams: { id },
        method: "DELETE",
        token: access_token
    })
    .then(() => dispatch({ type: types.DELETE_PATIENT_APPOINTMENT_SUCCESS, id }))
    .catch(error => dispatch({ type: types.DELETE_PATIENT_APPOINTMENT_FAILURE, data: error }));
};

export const tryCreatePatientAppointment = (appointment) => dispatch => {
    const { access_token } = store.getState().auth;
    
    dispatch({ type: types.CREATE_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments" : "/dap-hcp-patient/api/v1/customers/patients/appointments",
        method: "POST",
        token: access_token,
        data: {
            recommendations: appointment.recommendations,
            patient: appointment.patientIri,
            practitioner: appointment.practitioner["@id"],
            date: appointment.date,
            topic: appointment.topic
        }
    })
    .then(response => {
        dispatch({ type: types.CREATE_PATIENT_APPOINTMENT_SUCCESS, data: response });
        return response;
    })
    .catch(error => dispatch({ type: types.CREATE_PATIENT_APPOINTMENT_FAILURE, date: error }));
};

export const tryEditPatientAppointment = (appointment) => dispatch => {
    const { access_token } = store.getState().auth;

    dispatch({ type: types.EDIT_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments/{id}" : "/dap-hcp-patient/api/v1/customers/patients/appointments/{id}",
        uriParams: { id: appointment.id },
        method: "PUT",
        token: access_token,
        data: {
            recommendations: appointment.recommendations,
            patient: appointment.patientIri,
            practitioner: appointment.practitionerIri,
            date: appointment.date,
            topic: appointment.topic,
            doneAt: appointment.doneAt
        }
    })
    .then(response => {
        dispatch({ type: types.EDIT_PATIENT_APPOINTMENT_SUCCESS, data: response });
        return response;
    })
    .catch(error => dispatch({ type: types.EDIT_PATIENT_APPOINTMENT_FAILURE, data: error }));
};
