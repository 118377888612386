import * as types from "../actions/types";

import { toast } from "react-toastify";

const initialState = {
    searchMedical: [],
    searchMedicalLoading: false,

    patientMedicals: [],
    patientMedicalsLoading: false,
    patientMedicalsLoaded: 0,

    patientDeleteLoading: false,

    patientCreateLoading: false,

    patientEnableLoading: false
};

function medicals(state = initialState, action) {
    switch (action.type) {
        case types.SEARCH_MEDICAL_REQUESTED: {
            return Object.assign({}, state, {
                searchMedicalLoading: true 
            });
        }
        case types.SEARCH_MEDICAL_SUCCESS: {
            return Object.assign({}, state, {
                searchMedicalLoading: false,
                searchMedical: action.data
            });
        }
        case types.SEARCH_MEDICAL_FAILURE: {
            return Object.assign({}, state, {
                searchMedicalLoading: false,
                searchMedical: []
            });
        }

        case types.PATIENT_MEDICAL_REQUESTED: {
            return Object.assign({}, state, {
                patientMedicalsLoading: true,
                patientMedicalsLoaded: 0,
            });
        }
        case types.PATIENT_MEDICAL_SUCCESS: {
            return Object.assign({}, state, {
                patientMedicalsLoading: false,
                patientMedicals: action.data,
                patientMedicalsLoaded: Date.now(),
            });
        }
        case types.PATIENT_MEDICAL_FAILURE: {
            return Object.assign({}, state, {
                patientMedicalsLoading: false,
                patientMedicals: [],
                patientMedicalsLoaded: 0,
            });
        }

        case types.PATIENT_MEDICAL_DELETE_REQUESTED: {
            return Object.assign({}, state, {
                patientDeleteLoading: true
            });
        }
        case types.PATIENT_MEDICAL_DELETE_SUCCESS: {
            toast.success("Le médecin a été retiré");
            return Object.assign({}, state, {
                patientDeleteLoading: false,
                patientMedicals: state.patientMedicals.filter(accessRight => accessRight["@id"] !== action.data),
            });
        }
        case types.PATIENT_MEDICAL_DELETE_FAILURE: {
            toast.error("Le médecin n'a pas pu être retiré");
            return Object.assign({}, state, {
                patientDeleteLoading: false
            });
        }

        case types.PATIENT_MEDICAL_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                patientCreateLoading: true
            });
        }
        case types.PATIENT_MEDICAL_CREATE_SUCCESS: {
            return Object.assign({}, state, {
                patientCreateLoading: false,
                patientMedicals: [action.data, ...state.patientMedicals],
            });
        }
        case types.PATIENT_MEDICAL_CREATE_FAILURE: {
            return Object.assign({}, state, {
                patientCreateLoading: false
            });
        }

        case types.PATIENT_MEDICAL_ENABLE_REQUESTED: {
            return Object.assign({}, state, {
                patientEnableLoading: true
            });
        }
        case types.PATIENT_MEDICAL_ENABLE_SUCCESS: {
            toast.success("Le médecin a été ajouté");
            return Object.assign({}, state, {
                patientEnableLoading: false,
                patientMedicals: state.patientMedicals.map(accessRight => (
                    (accessRight["@id"] === action.data["@id"]) ? action.data : accessRight
                )),
            });
        }
        case types.PATIENT_MEDICAL_ENABLE_FAILURE: {
            toast.error("Le médecin n'a pas pu être ajouté");
            return Object.assign({}, state, {
                patientEnableLoading: false
            });
        }

        default:
            return state;
    }
}

export default medicals;
