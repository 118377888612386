import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    calendar: [],
    calendarLoading: false,
    calendarLoaded: 0,

    deleteLoading: false,
    deleteError: false,

    createLoading: false,
    createError: false,

    editLoading: false,
    editError: false
};

function calendar(state = initialState, action) {
    switch (action.type) {
        // Fetch all patient appointments
        case types.PATIENT_APPOINTMENTS_REQUESTED: {
            return Object.assign({}, state, {
                calendarLoading: true,
                calendarLoaded: 0,
            });
        }
        case types.PATIENT_APPOINTMENTS_SUCCESS: {
            return Object.assign({}, state, {
                calendarLoading: false,
                calendar: action.data,
                calendarLoaded: Date.now(),
            });
        }
        case types.PATIENT_APPOINTMENTS_FAILURE: {
            return Object.assign({}, state, {
                calendarLoading: false,
                calendar: [],
                calendarLoaded: 0,
            });
        }
        // Delete patient appointments
        case types.DELETE_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, {
                deleteLoading: true,
                deleteError: false
            });
        }
        case types.DELETE_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été supprimé");
            return Object.assign({}, state, {
                deleteLoading: false,
                calendar: state.calendar.filter(appointment => appointment.id !== action.id)
            });
        }
        case types.DELETE_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être supprimé");
            return Object.assign({}, state, {
                deleteLoading: false,
                deleteError: action.data
            });
        }
        // Create patient appointments
        case types.CREATE_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                createError: false
            });
        }
        case types.CREATE_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été crée");
            return Object.assign({}, state, {
                createLoading: false,
                calendar: [action.data, ...state.calendar]
            });
        }
        case types.CREATE_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être crée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data
            });
        }
        // Edit patient appointments
        case types.EDIT_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, {
                editLoading: true,
                editError: false
            });
        }
        case types.EDIT_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été modifié");
            return Object.assign({}, state, {
                editLoading: false,
                calendar: state.calendar.map(appointment =>
                    appointment.id === action.data.id ? action.data : appointment
                ),
                editError: false
            });
        }
        case types.EDIT_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être modifié");
            return Object.assign({}, state, {
                editLoading: false,
                editError: action.data
            });
        }
        default:
            return state;
    }
}

export default calendar;
