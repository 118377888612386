import { combineReducers } from "redux";

import authReducer from "./auth";
import patientsReducer from "./patients";
import medicalsReducer from "./medicals";
import measuresReducer from "./measures";
import diagnosisReducer from "./diagnosis";
import calendarReducer from "./calendar";
import profileReducer from "./profile";
import recommandationsReducer from "./recommandations";

const reducers = combineReducers({
    auth: authReducer,
    patients: patientsReducer,
    medicals: medicalsReducer,
    measures: measuresReducer,
    calendar: calendarReducer,
    diagnosis: diagnosisReducer,
    profile: profileReducer,
    recommandations: recommandationsReducer
});

export default reducers;
