import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryPatientMedical } from "../../actions/medicals";

import { HorizontalNavbarTab, DefaultLoader, FlashMessage, PageTitle, useLoadAfterPromises } from "@rdcs/dap-front-library";

import MedicalItem from "../../components/Medical/MedicalItem";
import AppLayout from "../../components/Layout/AppLayout";
import useNavBar from "./useNavBar";

const breadcrumbs = [{ label: "Mes soignants" }];

const MedicalScreen = ({ patientMedicals, tryPatientMedical }) => {
    const loadingRequestsAPI = React.useMemo(() => [tryPatientMedical], []);
    const loading = useLoadAfterPromises(loadingRequestsAPI);

    const navBar = useNavBar();

    return (
        <PageTitle title="Timkl - Mes soignants">
            <AppLayout navActive={2} breadcrumbs={breadcrumbs}>
                <HorizontalNavbarTab navbar={navBar} tabActive={0} />
                <div>
                    {/*
                    <Link to="/mes-soignants/ajouter" className="blockList__header-action blockList__header-add btn btn-slim">
                        Ajouter un professionnel de santé
                    </Link>
                    */}
                </div>
                <div className="form__group">
                    {loading ? (
                        <DefaultLoader color="#CCC" />
                    ) : patientMedicals.length > 0 ? (
                        patientMedicals.map((item) => <MedicalItem key={item["@id"]} medical={item.user} accessRight={item} />)
                    ) : (
                        <FlashMessage title="Aucun médecin" dismissible={false} />
                    )}
                </div>
            </AppLayout>
        </PageTitle>
    );
};

MedicalScreen.propTypes = {
    patientMedicals: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryPatientMedical: PropTypes.func.isRequired,
};

const mapStateToProps = ({ medicals: { patientMedicals } }) => ({ patientMedicals });

const mapDispatchToProps = {
    tryPatientMedical,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalScreen);
