import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link, useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { tryGigyaNewPassword } from "../../../actions/auth";

import * as queryString from "query-string";

import { Icon, Button, InputPassword, PatientLayoutAnonymous, PatientLayoutAsideAnonymous, PageTitle } from "@rdcs/dap-front-library";

import { LOGIN_URL } from "../../../utils/utils";

const NewPasswordScreen = ({ auth, tryGigyaNewPassword }) => {
    const history = useHistory();
    const location = useLocation();

    const urlParams = React.useMemo(() => queryString.parse(history.location.search), [history.location.search]);

    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        tryGigyaNewPassword(urlParams.token, password, confirmPassword);
    };

    React.useEffect(() => {
        if (auth.newPasswordSaved) {
            setTimeout(() => {
                window.location.href = LOGIN_URL;
            }, 1000);
        }
    }, [auth.newPasswordSaved]);

    if (!(auth.newPasswordSaved || urlParams.token)) {
        return (
            <Redirect
                to={{
                    pathname: "/",
                    state: { from: location },
                }}
            />
        );
    }

    return (
        <PageTitle title="Timkl - Nouveau mot de passe">
            <div className="wrapper sitePatient bg-connexion-patient">
                <PatientLayoutAnonymous>
                    <PatientLayoutAsideAnonymous />
                    <form onSubmit={handleSubmit} className="credentialsMain__container">
                        <div className="credentialsMain">
                            <Link to="/" className="credentialsMain__back">
                                <Icon name="arrow-left" />
                            </Link>
                            <div className="nolabelGroup mb-3">
                                <h1 className="title-1">Nouveau mot de passe</h1>
                            </div>
                            <div className="grid grid__padding-10">
                                <div className="col-xs-50">
                                    <InputPassword
                                        label="Nouveau mot de passe"
                                        placeholder="Saisissez votre nouveau mot de passe"
                                        onChange={setPassword}
                                        error={auth.newPasswordError}
                                    />
                                </div>
                                <div className="col-xs-50">
                                    <InputPassword label="Confirmez" placeholder="Confirmez votre mot de passe" onChange={setConfirmPassword} />
                                </div>
                            </div>
                            <div className="credentialsMain__validate">
                                <Button type="submit" className="button__primaryButton" isLoading={auth.newPasswordLoading}>
                                    Valider
                                </Button>
                            </div>
                        </div>
                    </form>
                </PatientLayoutAnonymous>
            </div>
        </PageTitle>
    );
};

NewPasswordScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    tryGigyaNewPassword: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
    tryGigyaNewPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordScreen);
