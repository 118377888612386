import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchProfile, tryEditProfile } from "../../actions/profile";

import * as queryString from "query-string";
import { Animated } from "react-animated-css";
import { toast } from "react-toastify";

import {
    DefaultLoader,
    HorizontalNavbarTab,
    getPrivateFileUrl,
    downloadFileLink,
    getViolations,
    PageTitle,
    useLoadAfterPromises,
} from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";
import ProfileForm from "../../components/Profile/ProfileForm";
import navBar from "./navBar";

const ProfileScreen = ({ profile, profileEditLoading, profileEditError, tryFetchProfile, tryEditProfile }) => {
    const history = useHistory();

    const urlParams = React.useMemo(() => queryString.parse(history.location.search), [history.location.search]);

    const loadingRequestsAPI = React.useMemo(() => [tryFetchProfile], []);
    const loading = useLoadAfterPromises(loadingRequestsAPI);

    React.useEffect(() => {
        if (urlParams.downloadPersonalData) {
            toast.info("Vos données personnelles sont en cours de téléchargement", { autoClose: 5000 });
            getPrivateFileUrl(urlParams.downloadPersonalData)
                .then((file) => {
                    downloadFileLink(file.url, "Personal_data.zip");
                    toast.success("Vos données personnelles ont bien été téléchargées.", { autoClose: 5000 });
                })
                .catch(() => toast.error("Une erreur s'est produite", { autoClose: 5000 }));
        }
    }, []);

    return (
        <PageTitle title="Timkl - Profil">
            <AppLayout navActive={null}>
                <HorizontalNavbarTab navbar={navBar} tabActive={0} />
                <div>
                    {loading ? (
                        <DefaultLoader color="#CCC" />
                    ) : (
                        <Animated animationIn="fadeIn">
                            <ProfileForm submit={tryEditProfile} profile={profile} profileEditLoading={profileEditLoading} violations={profileEditError} />
                        </Animated>
                    )}
                </div>
            </AppLayout>
        </PageTitle>
    );
};

ProfileScreen.propTypes = {
    profile: PropTypes.object,
    profileEditLoading: PropTypes.bool.isRequired,
    profileEditError: PropTypes.object.isRequired,
    tryFetchProfile: PropTypes.func.isRequired,
    tryEditProfile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profile: { profile, profileEditLoading, profileEditError } }) => ({
    profile,
    profileEditLoading,
    profileEditError: getViolations(profileEditError),
});

const mapDispatchToProps = {
    tryFetchProfile,
    tryEditProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
