import React from "react";
import PropTypes from "prop-types";

import Moment from "moment";
import "moment/locale/fr";

import { Animated } from "react-animated-css";
import AddToCalendar from "react-add-to-calendar";

import { Icon } from "@rdcs/dap-front-library";

const CalendarItem = ({ appointment: { date: appointmentDate, topic, practitioner = null } }) => {
    const date = React.useMemo(() => Moment(appointmentDate).locale("fr"), [appointmentDate]);

    // const [isVisible, setIsVisible] = React.useState(true);

    /*const delete = (e) => {
        e.preventDefault();
        setIsVisible(false);
        delete(id);
    }*/

    return (
        <Animated animationIn="fadeIn" animationOut="fadeOut" /*isVisible={isVisible}*/>
            <div className="moderationListItem recommendationsPatientListItem">
                <div className="moderationListItem__infos">
                    <div className="moderationListItem__name">{topic}</div>
                    <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                        <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                            {practitioner ? `${practitioner.firstName} ${practitioner.lastName}` : "Praticien anonyme"}
                        </span>
                    </div>
                    <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                        <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                            {date.format("[Le] Do MMMM YYYY [à] H[h]mm")}
                        </span>
                    </div>
                    <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                        <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                            {practitioner && `${practitioner.address}, ${practitioner.zipcode} ${practitioner.city}`}
                        </span>
                    </div>
                </div>
                <div className="moderationListItem__status moderationListItem__status--widthAuto">
                    <div className="moderationListItem__actions-content">
                        <div className="moderationListItem__action">
                            <Icon name="check-circle" className="moderationListItem__action-icon" />
                            <AddToCalendar
                                buttonLabel="Ajouter à mon agenda"
                                event={{
                                    title: practitioner
                                        ? `Rendez-vous avec ${practitioner.firstName} ${practitioner.lastName} : ${topic}`
                                        : "Rendez-vous avec Praticien anonyme",
                                    description: "",
                                    location: practitioner ? `${practitioner.address}, ${practitioner.zipcode} ${practitioner.city}` : "",
                                    startTime: date.format(),
                                    endTime: date.add(1, "hour").format(),
                                }}
                            />
                        </div>
                        {/*<button className="moderationListItem__action" onClick={this.delete}>
                            <Icon name="cross-circle" className="moderationListItem__action-icon" />
                            Retirer le rendez-vous
                        </button>*/}
                    </div>
                </div>
            </div>
        </Animated>
    );
};

CalendarItem.propTypes = {
    appointment: PropTypes.shape({
        id: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        topic: PropTypes.string.isRequired,
        practitioner: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
            zipcode: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
        }),
    }).isRequired,
    //delete: PropTypes.func.isRequired,
};

export default React.memo(CalendarItem);
