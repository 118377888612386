import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryFetchRecommandations } from "../../actions/recommandations";
import { tryCreatePatientAppointment, tryPatientAppointments, tryEditPatientAppointment } from "../../actions/calendar";

import {
    DefaultLoader,
    HorizontalNavbarTab,
    Icon,
    AppointmentRecommended,
    AppointmentScheduled,
    PageTitle,
    useLoadAfterPromises,
} from "@rdcs/dap-front-library";
import moment from "moment";

import AppLayout from "../../components/Layout/AppLayout";
import fetchHealthProfessionals from "../../utils/fetchHealthProfessionals";
import navBar from "./navBar";

const breadcrumbs = [{ label: "Mes recommandations" }];

const RecommandationsScreen = ({
    user,
    recos,
    calendar,
    calendarCreateLoading,
    calendarEditLoading,
    tryFetchRecommandations,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryPatientAppointments,
}) => {
    const loadingRequestsAPI = React.useMemo(() => [tryFetchRecommandations, tryPatientAppointments], []);
    const loading = useLoadAfterPromises(loadingRequestsAPI);

    const recosInfo = React.useMemo(() => recos.filter((r) => r.recommendation.type === "information"), [recos]);

    const appointments = React.useMemo(() => calendar.filter((item) => item.recommendations.find((r) => r.type === "consultation")), [calendar]);

    const appointmentsPlanned = React.useMemo(
        () => appointments.filter((item) => item.doneAt === null).sort((a, b) => new Date(a.date) - new Date(b.date)),
        [appointments]
    );

    const recoConsults = React.useMemo(() => {
        const now = moment().unix();

        return (
            recos
                // Keep consulations only
                .filter(({ recommendation: { type, id: _id } }) => {
                    if (type === "consultation") {
                        for (const appointment of appointments) {
                            if (appointment.recommendations.find((recommendation) => recommendation.id === _id)) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                })
                // Bind Diagnostic recos with appointments
                .map((reco) => ({ ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" }))
                // Remove all consultation done.
                .filter(({ appointment = null }) => !appointment?.doneAt)
                // Sort by color and after, sort by dueAt
                .sort((a, b) => {
                    const codeA = a.statusColor.charCodeAt(0);
                    const codeB = b.statusColor.charCodeAt(0);

                    if (codeA !== codeB) {
                        return codeB - codeA;
                    }
                    return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
                })
        );
    }, [recos, appointments]);

    return (
        <PageTitle title="Timkl - Recommandations">
            <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
                <div className="recommendationsPatientHeader">
                    <div className="recommendationsPatientHeader__contentHolder">
                        <p className="recommendationsPatientHeader__emoji">
                            <span role="img" aria-label="WaveHandEmoji">
                                👋
                            </span>
                        </p>
                        <p className="recommendationsPatientHeader__title">Bonjour {user.firstName}</p>
                        <div className="informationsList mt-3">
                            {recosInfo.map((reco) => (
                                <div className="informationsList__item mt-2" key={reco.id}>
                                    <span className="informationsList__item-sourceLink">
                                        <Icon name="puce-reco" />
                                    </span>
                                    {reco.recommendation.title}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <HorizontalNavbarTab navbar={navBar} tabActive={0} />
                <div>
                    {loading ? (
                        <DefaultLoader color="#CCC" />
                    ) : (
                        <div className="moderationList">
                            {recoConsults.map((recoInstance) => (
                                <AppointmentRecommended
                                    key={recoInstance.id}
                                    dueAt={recoInstance.dueAt}
                                    statusColor={recoInstance.statusColor}
                                    recommendation={recoInstance.recommendation}
                                    showDescription={false}
                                    exams={recos.filter(({ recommendation: { consultationName } }) => consultationName === recoInstance.recommendation.title)}
                                    patientIri={"/patients/" + user.id}
                                    searchPractitionerAction={fetchHealthProfessionals}
                                    createAppointmentLoading={calendarCreateLoading}
                                    createAppointmentAction={tryCreatePatientAppointment}
                                />
                            ))}
                            {appointmentsPlanned.map((appointment) => (
                                <AppointmentScheduled
                                    key={appointment.id}
                                    appointment={appointment}
                                    confirmAppointmentLoading={calendarEditLoading}
                                    confirmAppointmentAction={tryEditPatientAppointment}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </AppLayout>
        </PageTitle>
    );
};

RecommandationsScreen.propTypes = {
    user: PropTypes.object.isRequired,
    recos: PropTypes.arrayOf(PropTypes.object).isRequired,
    calendar: PropTypes.arrayOf(PropTypes.object).isRequired,
    calendarCreateLoading: PropTypes.bool.isRequired,
    calendarEditLoading: PropTypes.bool.isRequired,
    tryFetchRecommandations: PropTypes.func.isRequired,
    tryPatientAppointments: PropTypes.func.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    tryEditPatientAppointment: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { user }, recommandations: { recos }, calendar: { calendar, createLoading, editLoading } }) => ({
    user,
    recos,
    calendar,
    calendarCreateLoading: createLoading,
    calendarEditLoading: editLoading,
});

const mapDispatchToProps = {
    tryFetchRecommandations,
    tryPatientAppointments,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommandationsScreen);
