import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { /*tryDeletePatientAppointment,*/ tryPatientAppointments } from "../../actions/calendar";

import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { HorizontalNavbarTab, DefaultLoader, Icon, FlashMessage, PageTitle, useLoadAfterPromises } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";
import CalendarItem from "../../components/Calendar/CalendarItem";

const navBar = [
    {
        title: "Mon calendrier",
        path: "/mon-calendrier",
    },
];

const breadcrumbs = [{ label: "Mon calendrier" }];

const CalendarScreen = ({
    user,
    calendar,
    tryPatientAppointments,
    // tryDeletePatientAppointment,
}) => {
    const loadingRequestsAPI = React.useMemo(() => [tryPatientAppointments], []);
    const loading = useLoadAfterPromises(loadingRequestsAPI);

    const calendarUrl = React.useMemo(() => `${window.env.API_URL}/calendar/${user.username}`, []);

    return (
        <PageTitle title="Timkl - Mon calendrier">
            <AppLayout navActive={3} breadcrumbs={breadcrumbs}>
                <HorizontalNavbarTab navbar={navBar} tabActive={0} />
                <div className="grid">
                    <div className="col-md-45 col-sm-100 mb-2">
                        <p>Vous pouvez exporter et imprimer le calendrier de vos prochains rendez-vous !</p>
                        <button onClick={window.print}>
                            <Icon name="pdf" className="mr-2" />
                            Télécharger un export pdf
                        </button>
                    </div>

                    <div className="col-md-45 col-md-offset-10 col-sm-100 mb-2">
                        <p>Synchroniser avec mon agenda :</p>
                        <p>Copiez l'url ci-dessous dans votre agenda Google Calendar, Outlook ou autre</p>
                        <CopyToClipboard text={calendarUrl} onCopy={() => toast.success("URL du calendrier copié", { autoClose: 3000 })}>
                            <button className="recommendationsPatientListItem__modalLink">{calendarUrl}</button>
                        </CopyToClipboard>
                    </div>
                    {loading ? (
                        <DefaultLoader color="#CCC" />
                    ) : (
                        <div className="col-100">
                            {calendar.length > 0 ? (
                                calendar.map((item) => (
                                    <CalendarItem
                                        key={item["@id"]}
                                        appointment={item}
                                        //delete={tryDeletePatientAppointment}
                                    />
                                ))
                            ) : (
                                <FlashMessage title="Aucun rendez-vous" dismissible={false} />
                            )}
                        </div>
                    )}
                </div>
            </AppLayout>
        </PageTitle>
    );
};

CalendarScreen.propTypes = {
    user: PropTypes.object.isRequired,
    calendar: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryPatientAppointments: PropTypes.func.isRequired,
    // tryDeletePatientAppointment: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { user }, calendar: { calendar } }) => ({ user, calendar });

const mapDispatchToProps = {
    tryPatientAppointments,
    // tryDeletePatientAppointment
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarScreen);
