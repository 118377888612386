import { toast } from "react-toastify";

import * as types from "../actions/types";

import TokenData from "../utils/TokenData";

const initialState = {
    ...TokenData.retrieve(),

    retrieveTokenFromCodeLoading: false,
    retrieveTokenFromCodeError: false,

    refreshTokenLoading: false,
    refreshTokenError: false,

    newPasswordLoading: false,
    newPasswordError: null,
    newPasswordSaved: false
};

function auth(state = initialState, action) {
    switch (action.type) {
        // Retrieve token from code gigya
        case types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED: {
            return Object.assign({}, state, {
                retrieveTokenFromCodeLoading: true,
                retrieveTokenFromCodeError: false
            });
        }
        case types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS: {
            TokenData.store(action.data);
            return Object.assign({}, state, {
                retrieveTokenFromCodeLoading: false,
                ...TokenData.retrieve()
            });
        }
        case types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE: {
            return Object.assign({}, state, {
                retrieveTokenFromCodeLoading: false,
                retrieveTokenFromCodeError: action.data.body["hydra:description"]
            });
        }
        // Refresh token gygia
        case types.GIGYA_REFRESH_TOKEN_REQUESTED: {
            return Object.assign({}, state, {
                refreshTokenLoading: true,
                refreshTokenError: false
            });
        }
        case types.GIGYA_REFRESH_TOKEN_SUCCESS: {
            TokenData.store({ ...action.data, user: TokenData.retrieve().user });
            return Object.assign({}, state, {
                refreshTokenLoading: false,
                ...TokenData.retrieve()
            });
        }
        case types.GIGYA_REFRESH_TOKEN_FAILURE: {
            return Object.assign({}, state, {
                refreshTokenLoading: false,
                refreshTokenError: true
            });
        }
        // Get userinfo
        case types.USERINFO_REQUESTED: {
            return Object.assign({}, state, {
                userInfoLoading: true,
                userInfoError: false
            });
        }
        case types.USERINFO_SUCCESS: {
            TokenData.store({ ...TokenData.retrieve(), user: action.data });
            return Object.assign({}, state, {
                userInfoLoading: false,
                ...TokenData.retrieve()
            });
        }
        case types.USERINFO_FAILURE: {
            return Object.assign({}, state, {
                userInfoLoading: false,
                userInfoError: true
            });
        }
        // NEW PASSWORD
        case types.GIGYA_NEW_PASSWORD_REQUESTED: {
            return Object.assign({}, state, {
                newPasswordLoading: true,
                newPasswordError: null
            });
        }
        case types.GIGYA_NEW_PASSWORD_SUCCESS: {
            toast.success("Nouveau mot de passe enregistré");
            return Object.assign({}, state, {
                newPasswordLoading: false,
                newPasswordSaved: true,
                newPasswordError: null
            });
        }
        case types.GIGYA_NEW_PASSWORD_FAILURE: {
            return Object.assign({}, state, {
                newPasswordLoading: false,
                newPasswordSaved: false,
                newPasswordError: Array.isArray(action.data.body) ? action.data.body[0] : "Requete invalide"
            });
        }

        // Logout
        case types.LOGOUT_SUCCESS: {
            TokenData.reset();
            toast.info("Vous avez été déconnecté");
            return Object.assign({}, state, {
                ...TokenData.retrieve()
            });
        }
        default:
            return state;
    }
}

export default auth;
