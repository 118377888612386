import React from "react";
import PropTypes from "prop-types";

import { PatientLayoutAnonymous } from "@rdcs/dap-front-library";

const ErrorScreen = ({ title, content }) => (
    <PatientLayoutAnonymous>
        <div className="appLoading">
            <div className="appLoading__container">
                <h1 className="title-1">{title}</h1>
                <p className="text-3">{content}</p>
            </div>
        </div>
    </PatientLayoutAnonymous>
);

ErrorScreen.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default ErrorScreen;
