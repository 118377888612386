import * as types from "../actions/types";

import { getConsultDateFromRecurrence } from "../utils/utils";

const initialState = {
    recosLoading: false,
    recosLoaded: 0,
    recosPatientId: -1,
    recosDiagnostisId: null,
    recos: [],
};

const sortRecos = (recos) => recos.sort((a, b) => new Date(a.dueAt) - new Date(b.dueAt));

function recommandations(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case types.RECOMMANDATIONS_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                recosLoading: true,
            });
        }
        case types.RECOMMANDATIONS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                recosLoading: false,
                recos: sortRecos(action.data),
                recosPatientId: action.patientId,
                recosDiagnostisId: action.diagnosisId,
                recosLoaded: Date.now(),
            });
        }
        case types.RECOMMANDATIONS_FETCH_FAILURE: {
            return Object.assign({}, state, {
                recosLoading: false,
                recos: [],
                recosPatientId: -1,
                recosDiagnosticId: null,
                recosLoaded: 0,
            });
        }

        // Appointment created
        case types.CREATE_PATIENT_APPOINTMENT_SUCCESS: {
            return Object.assign({}, state, {
                recoDoneLoading: false,
                recos: sortRecos(
                    state.recos.map(r => {
                        if (r["@id"] === action.data.diagnosticRecommendation) {
                            r.appointments.push(action.data);
                            r.dueAt = getConsultDateFromRecurrence(
                                new Date(),
                                r.recommendation.recurrence
                            );
                        }
                        return r;
                    })
                )
            });
        }

        default:
            return state;
    }
}

export default recommandations;
