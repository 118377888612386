const navBar = [
    {
        title: "Mon profil",
        path: "/mon-profil"
    },
    {
        title: "Export de données",
        path: "/mon-profil/export"
    }
];

export default navBar;