import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "moment";

import { tryFetchPatientMeasures, tryFetchMeasureTypes, tryCreatePatientMeasures } from "../../../actions/measures";
import { tryFetchLastDiagnosis } from "../../../actions/diagnosis";

import AppLayout from "../../../components/Layout/AppLayout";
import {
    HorizontalNavbarTab,
    DefaultLoader,
    Icon,
    InputDateTime,
    InputSymbol,
    getViolations,
    PageTitle,
    useLoadAfterPromises,
    ChartLine,
} from "@rdcs/dap-front-library";

import useState from "./useState";

const navBar = [
    {
        title: "Mes données",
        path: "/mes-donnees",
    },
];

const breadcrumbs = [{ label: "Mes données" }];

const DataScreen = ({
    user,
    measures,
    measureTypes,
    measureCreateError,
    patientLastDiagnosis,
    tryFetchPatientMeasures,
    tryFetchMeasureTypes,
    tryFetchLastDiagnosis,
    tryCreatePatientMeasures,
}) => {
    const loadingRequestsAPI = React.useMemo(() => [tryFetchPatientMeasures, tryFetchMeasureTypes, tryFetchLastDiagnosis], []);
    const loading = useLoadAfterPromises(loadingRequestsAPI);

    const [{ itemsToShow, showAddItem, addItem }, { setTypeAddItem, setDateAddItem, setValueAddItem, toggleShowAddItem, extendsItemsToShow }] = useState(
        user.id
    );

    const hBA1c = React.useMemo(() => measures.filter((measure) => measure.type.name === "HbA1c").sort((a, b) => (b.date > a.date ? -1 : 1)), [measures]);
    const hBA1cShow = React.useMemo(() => [...hBA1c].reverse().slice(0, itemsToShow), [hBA1c, itemsToShow]);
    const chartDates = React.useMemo(() => hBA1c.map(({ date }) => date), [hBA1c]);
    const chartValues = React.useMemo(() => hBA1c.map(({ value }) => parseFloat(value)), [hBA1c]);
    const chartUnit = React.useMemo(() => hBA1c[0]?.type.unit || "", [hBA1c]);
    const hba1cObjectif = React.useMemo(
        () => parseFloat(patientLastDiagnosis?.goal || chartValues[chartValues.length - 1] || 0),
        [patientLastDiagnosis, chartValues]
    ); // Last diagnosis HbA1c || Last data HbA1c
    const hba1cObjectifString = React.useMemo(() => hba1cObjectif.toFixed(1), [hba1cObjectif]);

    React.useEffect(() => {
        setTypeAddItem(measureTypes);
    }, [measureTypes, hBA1c, hba1cObjectif]);

    const chartDatesRender = React.useMemo(
        () => (chartDates.length > 5 ? chartDates.slice(chartDates.length - 5, chartDates.length) : chartDates),
        [chartDates]
    );
    const chartValuesRender = React.useMemo(
        () => (chartValues.length > 5 ? chartValues.slice(chartValues.length - 5, chartValues.length) : chartValues),
        [chartValues]
    );

    return (
        <PageTitle title="Timkl - Mes données">
            <AppLayout navActive={1} breadcrumbs={breadcrumbs}>
                <HorizontalNavbarTab navbar={navBar} tabActive={0} />
                {loading ? (
                    <DefaultLoader color="#CCC" />
                ) : (
                    <div className="dataPatientlist">
                        <div className="dataPatientlist__header">
                            <span className="dataPatientlist__headerName">HbA1c</span>
                            <span className="dataPatientlist__headerAction">
                                <span className="dataPatientlist__headerAction-item" onClick={toggleShowAddItem}>
                                    <Icon className="dataPatientlist__headerAction-content" name="add" size="30px" />
                                    <span className="dataPatientlist__headerAction-content">Ajouter une mesure HbA1c</span>
                                </span>
                            </span>
                        </div>
                        {showAddItem && (
                            <form
                                className="dataPatientItem dataPatientItem--action"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    tryCreatePatientMeasures(addItem);
                                }}
                            >
                                <div className="dataPatientItem__title">HbA1c</div>
                                <div className="dataPatientItem__form">
                                    <div className="dataPatientItem__inputGroup inlineForm">
                                        <InputDateTime
                                            label="Date"
                                            onChange={setDateAddItem}
                                            maxDate={new Date()}
                                            error={measureCreateError.date}
                                            required
                                            requiredNotInLabel
                                        />
                                    </div>
                                    <div className="dataPatientItem__inputGroup inlineForm">
                                        <InputSymbol
                                            label="Valeur"
                                            symbol="%"
                                            onChange={setValueAddItem}
                                            error={measureCreateError.value}
                                            required
                                            requiredNotInLabel
                                        />
                                    </div>
                                </div>
                                <div className="dataPatientItem__right">
                                    <button type="submit">
                                        <Icon className="dataPatientItem__submitForm" name="check-circle" size="32px" />
                                        <span className="dataPatientItem__submitForm">Valider l'ajout</span>
                                    </button>
                                </div>
                            </form>
                        )}
                        {hBA1cShow.length > 0 ? (
                            <>
                                {hBA1cShow.map((measure, index) => (
                                    <div key={index} className="dataPatientItem">
                                        <div className="dataPatientItem__title">
                                            {measure.type.name}
                                            <span className="dataPatientItem__titleSubtitle">{Moment(measure.date).format("[Le ] Do MMMM YYYY")}</span>
                                        </div>
                                        <div className="dataPatientItem__right">
                                            <div className="dataPatientItem__percent">{measure.value + " " + measure.type.unit}</div>
                                        </div>
                                    </div>
                                ))}
                                {hBA1cShow.length >= itemsToShow && (
                                    <div className="dataPatientItem__footerAction">
                                        <button className="dataPatientItem__footerAction-button" onClick={extendsItemsToShow}>
                                            Mesures précédentes
                                        </button>
                                    </div>
                                )}

                                <div className="contentHolder__title grid mt-4">
                                    <div className="col-md-20 col-sm-100 chartLabelContainer">
                                        <p className="chartLabel">Objectif HbA1c actuel :</p>
                                        <p className="chartLabelNumber">{hba1cObjectifString + chartUnit}</p>
                                    </div>
                                    <div className="col-md-80 col-sm-100">
                                        <ChartLine
                                            chartDates={chartDatesRender}
                                            chartValues={chartValuesRender}
                                            hba1cObjectif={hba1cObjectif}
                                            chartUnit={chartUnit}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <p>Il n'y a aucune donnée pour ce patient.</p>
                        )}
                    </div>
                )}
            </AppLayout>
        </PageTitle>
    );
};

DataScreen.propTypes = {
    user: PropTypes.object.isRequired,
    measures: PropTypes.arrayOf(PropTypes.object).isRequired,
    measureTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    measureCreateError: PropTypes.object.isRequired,
    patientLastDiagnosis: PropTypes.object,
    tryFetchPatientMeasures: PropTypes.func.isRequired,
    tryFetchMeasureTypes: PropTypes.func.isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
    tryCreatePatientMeasures: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { user }, measures: { measures, measureTypes, createError }, diagnosis: { patientLastDiagnosis } }) => ({
    user,
    measures,
    measureTypes,
    measureCreateError: getViolations(createError),
    patientLastDiagnosis,
});

const mapDispatchToProps = {
    tryFetchPatientMeasures,
    tryFetchMeasureTypes,
    tryFetchLastDiagnosis,
    tryCreatePatientMeasures,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataScreen);
