import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import history from "./config/history";

import { ScrollToTop } from "@rdcs/dap-front-library";
import PrivateRoute from "./components/Utils/PrivateRoute";

import LoginScreen from "./screens/Login/LoginScreen";
// import SignInScreen from "./screens/SignIn/SignInScreen";
import LogoutScreen from "./screens/Login/LogoutScreen";
import RecommandationsScreen from "./screens/Recommandations/RecommandationsScreen";
import DoneRecommandationsScreen from "./screens/Recommandations/DoneRecommandationsScreen";
import DataScreen from "./screens/Data/DataScreen";
import MedicalScreen from "./screens/Medical/MedicalScreen";
import MedicalDetailsScreen from "./screens/Medical/MedicalDetailsScreen";
// import MedicalAddScreen from "./screens/Medical/MedicalAddScreen";
import CalendarScreen from "./screens/Calendar/CalendarScreen";
// import HandlersScreen from "./screens/Handlers/HandlersScreen";
import ProfileScreen from "./screens/Profile/ProfileScreen";
import TermsOfUsageScreen from "./screens/Page/TermsOfUsageScreen";
import NotFoundExceptionScreen from "./screens/Exceptions/NotFoundExceptionScreen";
import ExportProfileScreen from "./screens/Profile/ExportProfileScreen";
// import HandlerAddScreen from "./screens/Handlers/HandlerAddScreen";
import LoginSuccessScreen from "./screens/Login/LoginSuccessScreen";
import NewPasswordScreen from "./screens/Email/NewPasswordScreen";

const App = () => (
    <Router history={history}>
        <ScrollToTop>
            <ToastContainer autoClose={3000} />
            <Switch>
                <Redirect exact from="/" to="/connexion" />
                <Route exact path="/connexion" component={LoginScreen} />
                <Route exact path="/login_success" component={LoginSuccessScreen} />
                {/*<Route exact path="/inscription" component={SignInScreen}/>*/}
                <Route exact path="/cgu" component={TermsOfUsageScreen} />
                <Route exact path="/email/new-password" component={NewPasswordScreen} />
                <PrivateRoute exact path="/mes-recommandations" component={RecommandationsScreen} />
                <PrivateRoute exact path="/mes-recommandations-effectuees" component={DoneRecommandationsScreen} />
                <PrivateRoute exact path="/mes-donnees" component={DataScreen} />
                <PrivateRoute exact path="/mes-soignants" component={MedicalScreen} />
                <PrivateRoute exact path="/mes-soignants/:id/details" component={MedicalDetailsScreen} />
                {/*<PrivateRoute exact path="/mes-soignants/ajouter" component={MedicalAddScreen}/>*/}
                <PrivateRoute exact path="/mon-calendrier" component={CalendarScreen} />
                {/*<PrivateRoute exact path="/mes-accompagnants" component={HandlersScreen} />*/}
                {/*<PrivateRoute exact path="/mes-accompagnants/ajouter" component={HandlerAddScreen} />*/}
                <PrivateRoute exact path="/mon-profil" component={ProfileScreen} />
                <PrivateRoute exact path="/mon-profil/export" component={ExportProfileScreen} />

                <Route exact path="/deconnexion" component={LogoutScreen} />
                <Route component={NotFoundExceptionScreen} />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default App;
