import React from "react";
import PropTypes from "prop-types";

import { Button, InputText, InputTel, InputEmail, InputRadio, InputImage, InputDateTime, InputCheckbox, constant } from "@rdcs/dap-front-library";

import API from "../../utils/API";

const ProfileForm = ({ submit, profile, profileEditLoading, violations }) => {
    const [firstName, setFirstName] = React.useState(profile.firstName || "");
    const [lastName, setLastName] = React.useState(profile.lastName || "");
    const [dateOfBirth, setDateOfBirth] = React.useState(profile.dateOfBirth || "");
    const [sex, setSex] = React.useState(profile.sex || "");
    const [ssn, setSsn] = React.useState(profile.ssn || "");
    const [phoneNumber, setPhoneNumber] = React.useState(profile.phoneNumber || "");
    //const [email, setEmail] = React.useState(profile.email || '');
    const [avatar, setAvatar] = React.useState(profile.avatar?.["@id"] || null);
    const [avatarUploaded, setAvatarUploaded] = React.useState(false);
    const [emailNotifications, setEmailNotifications] = React.useState(profile.emailNotifications || false);
    const [smsNotifications, setSmsNotifications] = React.useState(profile.smsNotifications || false);

    const uploadAvatarAction = (file) =>
        API.uploadFile(file)
            .then((item) => {
                setAvatar(item["@id"]);
                setAvatarUploaded(true);
            })
            .catch(() => {
                setAvatar(null);
                setAvatarUploaded(false);
                reject("L'upload a échoué pour une raison inconnue");
            });

    const handleSubmit = (e) => {
        const formSubmit = {
            firstName,
            lastName,
            dateOfBirth,
            sex,
            ssn,
            phoneNumber,
            email: profile.email,
            emailNotifications,
            smsNotifications,
        };

        e.preventDefault();

        if (avatarUploaded) {
            formSubmit.avatar = avatar;
        }
        submit(formSubmit);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputText
                        label="Votre prénom"
                        placeholder="Saisissez votre prénom"
                        name="firstName"
                        onChange={setFirstName}
                        error={violations.firstName}
                        defaultValue={firstName}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Votre nom"
                        placeholder="Saisissez votre nom"
                        name="lastName"
                        onChange={setLastName}
                        error={violations.lastName}
                        defaultValue={lastName}
                        required
                    />
                </div>
            </div>
            <InputDateTime
                label="Date de naissance"
                placeholder="jj/mm/aaaa"
                displayFormat="dd/MM/yyyy"
                error={violations.dateOfBirth}
                defaultValue={new Date(dateOfBirth)}
                onChange={setDateOfBirth}
                maxDate={new Date()}
                required
            />
            <InputRadio
                label="Sexe"
                error={violations.sex}
                defaultValue={sex}
                options={[
                    { label: constant("sexes", "male"), value: "male" },
                    { label: constant("sexes", "female"), value: "female" },
                ]}
                onChange={setSex}
                required
            />
            <InputText
                label="N° de sécurité sociale"
                placeholder="Saisissez votre numéro de sécurité sociale"
                onChange={setSsn}
                error={violations.ssn}
                defaultValue={ssn}
                required
            />
            <InputTel
                label="Téléphone portable"
                placeholder="Saisissez votre numéro de téléphone portable"
                onChange={setPhoneNumber}
                error={violations.phoneNumber}
                defaultValue={phoneNumber}
            />
            <InputEmail
                label="Votre email"
                legend="Cette adresse nous sert à vous communiquer des informations importantes. Nous ne la communiquerons à aucun tiers."
                disabled
                //onChange={setEmail}
                defaultValue={profile.email}
                error={violations.email}
                required
            />
            <InputImage
                label="Votre photo de profil"
                uploadAction={uploadAvatarAction}
                error={violations.avatar}
                defaultFilename={avatar?.filename}
                text="Télécharger"
            />
            <InputCheckbox
                label="Recevoir les notifications SMS (modifiable plus tard)"
                defaultChecked={smsNotifications}
                onChange={setSmsNotifications}
                error={violations.smsNotifications}
            />
            <InputCheckbox
                label="Recevoir les notifications par email (modifiable plus tard)"
                defaultChecked={emailNotifications}
                onChange={setEmailNotifications}
                error={violations.emailNotifications}
            />
            <div className="form__group">
                <Button type="submit" className="button__primaryButton" isLoading={profileEditLoading}>
                    Valider
                </Button>
            </div>
        </form>
    );
};

ProfileForm.propTypes = {
    submit: PropTypes.func.isRequired,
    profile: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string.isRequired,
        sex: PropTypes.string.isRequired,
        ssn: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string,
        email: PropTypes.string.isRequired,
        avatar: PropTypes.shape({
            "@id": PropTypes.string.isRequired,
            filename: PropTypes.string.isRequired,
        }),
        emailNotifications: PropTypes.bool,
        smsNotifications: PropTypes.bool,
    }).isRequired,
    profileEditLoading: PropTypes.bool.isRequired,
    violations: PropTypes.object.isRequired,
};

export default ProfileForm;
