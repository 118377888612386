/* AUTH */
export const GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED = "GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED";
export const GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS = "GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS";
export const GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE = "GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE";

export const USERINFO_REQUESTED = "USERINFO_REQUESTED";
export const USERINFO_SUCCESS = "USERINFO_SUCCESS";
export const USERINFO_FAILURE = "USERINFO_FAILURE";

export const GIGYA_REFRESH_TOKEN_REQUESTED = "GIGYA_REFRESH_TOKEN_REQUESTED";
export const GIGYA_REFRESH_TOKEN_SUCCESS = "GIGYA_REFRESH_TOKEN_SUCCESS";
export const GIGYA_REFRESH_TOKEN_FAILURE = "GIGYA_REFRESH_TOKEN_FAILURE";

export const GIGYA_NEW_PASSWORD_REQUESTED = "GIGYA_NEW_PASSWORD_REQUESTED";
export const GIGYA_NEW_PASSWORD_SUCCESS = "GIGYA_NEW_PASSWORD_SUCCESS";
export const GIGYA_NEW_PASSWORD_FAILURE = "GIGYA_NEW_PASSWORD_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

/* PATIENTS */
export const CREATE_PATIENT_REQUESTED = "CREATE_PATIENT_REQUESTED";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAILURE = "CREATE_PATIENT_FAILURE";

export const VALIDATE_PATIENT_SUCCESS = "VALIDATE_PATIENT_SUCCESS";
export const VALIDATE_PATIENT_FAILURE = "VALIDATE_PATIENT_FAILURE";

export const CANCEL_PATIENT_SUCCESS = "CANCEL_PATIENT_SUCCESS";

/* MEDICAL */
export const SEARCH_MEDICAL_REQUESTED = "SEARCH_MEDICAL_REQUESTED";
export const SEARCH_MEDICAL_SUCCESS = "SEARCH_MEDICAL_SUCCESS";
export const SEARCH_MEDICAL_FAILURE = "SEARCH_MEDICAL_FAILURE";

export const PATIENT_MEDICAL_REQUESTED = "PATIENT_MEDICAL_REQUESTED";
export const PATIENT_MEDICAL_SUCCESS = "PATIENT_MEDICAL_SUCCESS";
export const PATIENT_MEDICAL_FAILURE = "PATIENT_MEDICAL_FAILURE";

export const PATIENT_MEDICAL_DELETE_REQUESTED = "PATIENT_MEDICAL_DELETE_REQUESTED";
export const PATIENT_MEDICAL_DELETE_SUCCESS = "PATIENT_MEDICAL_DELETE_SUCCESS";
export const PATIENT_MEDICAL_DELETE_FAILURE = "PATIENT_MEDICAL_DELETE_FAILURE";

export const PATIENT_MEDICAL_CREATE_REQUESTED = "PATIENT_MEDICAL_CREATE_REQUESTED";
export const PATIENT_MEDICAL_CREATE_SUCCESS = "PATIENT_MEDICAL_CREATE_SUCCESS";
export const PATIENT_MEDICAL_CREATE_FAILURE = "PATIENT_MEDICAL_CREATE_FAILURE";

export const PATIENT_MEDICAL_ENABLE_REQUESTED = "PATIENT_MEDICAL_ENABLE_REQUESTED";
export const PATIENT_MEDICAL_ENABLE_SUCCESS = "PATIENT_MEDICAL_ENABLE_SUCCESS";
export const PATIENT_MEDICAL_ENABLE_FAILURE = "PATIENT_MEDICAL_ENABLE_FAILURE";

/* DATA */
export const PATIENT_DATA_REQUESTED = "PATIENT_DATA_REQUESTED";
export const PATIENT_DATA_SUCCESS = "PATIENT_DATA_SUCCESS";
export const PATIENT_DATA_FAILURE = "PATIENT_DATA_FAILURE";

export const PATIENT_DATA_CREATE_REQUESTED = "PATIENT_DATA_CREATE_REQUESTED";
export const PATIENT_DATA_CREATE_SUCCESS = "PATIENT_DATA_CREATE_SUCCESS";
export const PATIENT_DATA_CREATE_FAILURE = "PATIENT_DATA_CREATE_FAILURE";

export const PATIENT_DATA_TYPES_REQUESTED = "PATIENT_DATA_TYPES_REQUESTED";
export const PATIENT_DATA_TYPES_SUCCESS = "PATIENT_DATA_TYPES_SUCCESS";
export const PATIENT_DATA_TYPES_FAILURE = "PATIENT_DATA_TYPES_FAILURE";

/* CALENDAR */
export const PATIENT_APPOINTMENTS_REQUESTED = "PATIENT_APPOINTMENTS_REQUESTED";
export const PATIENT_APPOINTMENTS_SUCCESS = "PATIENT_APPOINTMENTS_SUCCESS";
export const PATIENT_APPOINTMENTS_FAILURE = "PATIENT_APPOINTMENTS_FAILURE";

export const DELETE_PATIENT_APPOINTMENT_REQUESTED = "DELETE_PATIENT_APPOINTMENT_REQUESTED";
export const DELETE_PATIENT_APPOINTMENT_SUCCESS = "DELETE_PATIENT_APPOINTMENT_SUCCESS";
export const DELETE_PATIENT_APPOINTMENT_FAILURE = "DELETE_PATIENT_APPOINTMENT_FAILURE";

export const CREATE_PATIENT_APPOINTMENT_REQUESTED = "CREATE_PATIENT_APPOINTMENT_REQUESTED";
export const CREATE_PATIENT_APPOINTMENT_SUCCESS = "CREATE_PATIENT_APPOINTMENT_SUCCESS";
export const CREATE_PATIENT_APPOINTMENT_FAILURE = "CREATE_PATIENT_APPOINTMENT_FAILURE";

export const EDIT_PATIENT_APPOINTMENT_REQUESTED = "EDIT_PATIENT_APPOINTMENT_REQUESTED";
export const EDIT_PATIENT_APPOINTMENT_SUCCESS = "EDIT_PATIENT_APPOINTMENT_SUCCESS";
export const EDIT_PATIENT_APPOINTMENT_FAILURE = "EDIT_PATIENT_APPOINTMENT_FAILURE";

/* PROFILE */
export const PROFILE_FETCH_REQUESTED = "PROFILE_FETCH_REQUESTED";
export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const PROFILE_FETCH_FAILURE = "PROFILE_FETCH_FAILURE";

export const PROFILE_EDIT_REQUESTED = "PROFILE_EDIT_REQUESTED";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAILURE = "PROFILE_EDIT_FAILURE";

/* RECOMMANDATIONS */
export const RECOMMANDATIONS_FETCH_REQUESTED = "RECOMMANDATIONS_FETCH_REQUESTED";
export const RECOMMANDATIONS_FETCH_SUCCESS = "RECOMMANDATIONS_FETCH_SUCCESS";
export const RECOMMANDATIONS_FETCH_FAILURE = "RECOMMANDATIONS_FETCH_FAILURE";

/* DIAGNOSIS */
export const PATIENT_LAST_DIAGNOSIS_REQUESTED = "PATIENT_LAST_DIAGNOSIS_REQUESTED";
export const PATIENT_LAST_DIAGNOSIS_SUCCESS = "PATIENT_LAST_DIAGNOSIS_SUCCESS";
export const PATIENT_LAST_DIAGNOSIS_FAILURE = "PATIENT_LAST_DIAGNOSIS_FAILURE";

/* HEALTH PROFESSIONALS */
export const HEALTH_PROFESSIONALS_FETCH_REQUESTED = "HEALTH_PROFESSIONALS_FETCH_REQUESTED";
export const HEALTH_PROFESSIONALS_FETCH_SUCCESS = "HEALTH_PROFESSIONALS_FETCH_SUCCESS";
export const HEALTH_PROFESSIONALS_FETCH_FAILURE = "HEALTH_PROFESSIONALS_FETCH_FAILURE";
export const HEALTH_PROFESSIONALS_FETCH_CLEAR = "HEALTH_PROFESSIONALS_FETCH_CLEAR";
