import React from "react";

const useNavBar = () =>
    React.useMemo(
        () => [
            {
                title: "Mes professionnels de santé",
                path: "/mes-soignants",
            },
        ],
        []
    );

export default useNavBar;
