import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { PatientLayout } from "@rdcs/dap-front-library";

const nav = [
    {
        path: "/mes-recommandations",
        icon: "prescription",
        title: "Mes recommandations",
    },
    {
        path: "/mes-donnees",
        icon: "datas",
        title: "Mes données",
    },
    {
        path: "/mes-soignants",
        icon: "pro",
        title: "Mes soignants",
    },
    {
        path: "/mon-calendrier",
        icon: "calendar1",
        title: "Mon calendrier",
    },
];

const userNav = [
    {
        title: "Profil",
        path: "/mon-profil",
        icon: "datas",
    },
    /*
    {
        title: "Mes accompagnants",
        path: "/mes-accompagnants",
        icon: "pro"
    },
    {
        title: "Mes appareils connectés",
        path: "/mes-appareils-connectes",
        icon: "check"
    },*/
    {
        title: "Se déconnecter",
        path: "/deconnexion",
        icon: "close",
    },
];

const AppLayout = ({ user, pageClass, navActive, children, breadcrumbs }) => (
    <PatientLayout nav={nav} userNav={userNav} user={user} pageClass={pageClass} navActive={navActive} breadcrumbs={breadcrumbs}>
        {children}
        <footer className="page__footer">
            <hr />
            <Link to="/cgu" className="page__footer--link">
                CGU
            </Link>
        </footer>
    </PatientLayout>
);

AppLayout.propTypes = {
    user: PropTypes.object.isRequired,
    navActive: PropTypes.number,
    pageClass: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            link: PropTypes.string,
        })
    ),
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default withRouter(connect(mapStateToProps)(AppLayout));
