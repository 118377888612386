import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { PatientLayoutAnonymous, PatientLayoutAsideAnonymous, PageTitle } from "@rdcs/dap-front-library";

import AppLayout from "../../../components/Layout/AppLayout";

import Content from "./Content";

const TermsOfUsageScreen = ({ user }) => (
    <PageTitle title="Timkl - Conditions d'utilisation">
        <div>
            {user ? (
                <AppLayout navActive={null}>
                    <Content />
                </AppLayout>
            ) : (
                <PatientLayoutAnonymous>
                    <PatientLayoutAsideAnonymous />
                    <Content />
                </PatientLayoutAnonymous>
            )}
        </div>
    </PageTitle>
);

TermsOfUsageScreen.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(TermsOfUsageScreen);
