const navBar = [
    {
        title: "À réaliser",
        path: "/mes-recommandations"
    },
    {
        title: "Réalisées",
        path: "/mes-recommandations-effectuees"
    }
];

export default navBar;