import { isLocalAPI } from "./utils";

export default class TokenData {
    static store(data) {
        // TODO: Find better way to store these var in dap-front-library
        window.fileRetrieveHeaders = {
            ...window.fileRetrieveHeaders,
            Authorization: isLocalAPI ? "Bearer " + data.id_token : "Bearer " + data.access_token
        };
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("id_token", data.id_token);
        localStorage.setItem("exp", data.exp);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("user", data.user ? JSON.stringify(data.user) : null);
    }

    static reset() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("exp");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
    }

    static retrieve() {
        return {
            access_token: localStorage.getItem("access_token") || null,
            exp: parseInt(localStorage.getItem("exp")) || null,
            refresh_token: localStorage.getItem("refresh_token") || null,
            id_token: localStorage.getItem("id_token") || null,
            user: TokenData.parseUser(localStorage.getItem("user")) || null
        };
    }

    static parseUser(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    }
}
