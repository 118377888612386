import * as types from "./types";

import store from '../store';

import { tryGigyaRefreshToken } from "./auth";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryFetchProfile = () => dispatch => {
    const {
        auth: {
            access_token,
            user: {
                id
            }
        },
        profile: {
            profile,
            profileLoaded
        }
    } = store.getState();

    if (profile?.id === id && profileLoaded) {
        return Promise.resolve(profile);
    }

    dispatch({ type: types.PROFILE_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients/{id}" : "/dap-hcp-patient/api/v1/customers/patients/{id}",
        uriParams: { id },
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.PROFILE_FETCH_SUCCESS, data: response });
        return response;
    })
    .catch(() => dispatch({ type: types.PROFILE_FETCH_FAILURE }));
};

export const tryEditProfile = (profile) => dispatch => {
    const {
        access_token,
        refresh_token,
        user: {
            id
        }
    } = store.getState().auth;

    dispatch({ type: types.PROFILE_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients/{id}" : "/dap-hcp-patient/api/v1/customers/patients/{id}",
        uriParams: { id },
        method: "PUT",
        token: access_token,
        data: profile
    })
    .then(response => {
        dispatch({ type: types.PROFILE_EDIT_SUCCESS, data: response });
        ;
    })
    .catch(error => {
        dispatch({ type: types.PROFILE_EDIT_FAILURE, data: error });
        throw error;
    })
    .then(() => dispatch(tryGigyaRefreshToken(refresh_token)));
};
