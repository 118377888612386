import React from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryPatientMedical, tryPatientMedicalDelete } from "../../actions/medicals";
import { /*tryDeletePatientAppointment,*/ tryPatientAppointments } from "../../actions/calendar";

import { HorizontalNavbarTab, Icon, Modal, Button, DefaultLoader, ImagePreview, FlashMessage, PageTitle, useLoadAfterPromises } from "@rdcs/dap-front-library";

import CalendarItem from "../../components/Calendar/CalendarItem";
import AppLayout from "../../components/Layout/AppLayout";

import useNavBar from "./useNavBar";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const breadcrumbs = [{ label: "Mes soignants", link: "/mes-soignants" }, { label: "Mon professionnel" }];

const MedicalDetailsScreen = ({
    patientMedicals,
    calendar,
    tryPatientAppointments,
    tryPatientMedical,
    tryPatientMedicalDelete,
    // tryDeletePatientAppointment
}) => {
    const { id } = useParams();
    const medicalId = React.useMemo(() => parseInt(id), [id]);

    const modalRef = React.useRef();

    const loadingRequestsAPI = React.useMemo(() => [tryPatientMedical, tryPatientAppointments], []);
    const loading = useLoadAfterPromises(loadingRequestsAPI);

    const patientMedical = React.useMemo(() => patientMedicals.find(({ user }) => user.id === medicalId) || null, [patientMedicals, medicalId]);
    const medical = React.useMemo(() => patientMedical?.user || null, [patientMedical]);

    const navBar = useNavBar();

    const appointments = React.useMemo(
        () =>
            medical
                ? calendar
                      .filter((item) => item.practitioner?.["@id"] === medical["@id"] && new Date() < new Date(item.date))
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                : [],
        [medical, calendar]
    );

    return (
        <PageTitle title="Timkl - Mes soignants">
            <AppLayout navActive={2} breadcrumbs={breadcrumbs}>
                <HorizontalNavbarTab navbar={navBar} tabActive={0} />
                {loading ? (
                    <DefaultLoader color="#CCC" />
                ) : (
                    <div>
                        {medical ? (
                            <div>
                                <div className="moderationListItem recommendationsPatientListItem">
                                    <ImagePreview
                                        className="moderationListItem__visuel"
                                        filename={medical.avatar && medical.avatar.filename}
                                        fallbackImageUrl={defaultUserImage}
                                    />
                                    <div className="moderationListItem__infos">
                                        <div className="moderationListItem__name">
                                            {medical.firstName} {medical.lastName}
                                        </div>
                                        <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                                            <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                                                {medical.medicalRole}
                                            </span>
                                        </div>
                                        <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                                            <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                                                {medical.address}, {medical.zipcode} {medical.city}
                                            </span>
                                        </div>
                                        <div className="moderationListItem__infos-list recommendationsPatientListItem__infos-list">
                                            <span className="moderationListItem__infos-item recommendationsPatientListItem__infos-item">
                                                {medical.phoneNumber1?.replace(/(\d)(?=(\d\d)+(?!\d))/g, "$1.")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="moderationListItem__actions moderationListItem__actions--onRight">
                                        <div className="moderationListItem__actions-content moderationListItem__actions--contentOnRight">
                                            <Modal
                                                buttonContent={
                                                    <button className="moderationListItem__action">
                                                        <Icon name="cross-circle" className="moderationListItem__action-icon" />
                                                        Retirer de mes soignants
                                                    </button>
                                                }
                                                ref={modalRef}
                                            >
                                                <div className="modal__title">
                                                    Voulez-vous supprimer {medical.firstName} {medical.lastName} de votre liste de médecins ?
                                                </div>
                                                <p>Cette action sera irréversible.</p>
                                                <div className="modal__btnGroup">
                                                    <Button className="button__secondaryButton" onClick={() => modalRef.current.toggleModal()}>
                                                        Annuler
                                                    </Button>
                                                    <Button className="button__primaryButton" onClick={() => tryPatientMedicalDelete(patientMedical)}>
                                                        Supprimer
                                                    </Button>
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>

                                {appointments.length > 0 ? (
                                    <div>
                                        <p className="my-2">
                                            Mes prochains rendez-vous avec {medical.firstName} {medical.lastName}
                                        </p>
                                        {appointments.map((item) => (
                                            <CalendarItem
                                                key={item["@id"]}
                                                appointment={item}
                                                //delete={tryDeletePatientAppointment}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <FlashMessage title="Aucun rendez-vous à venir" dismissible={false} />
                                )}
                            </div>
                        ) : (
                            <Redirect to="/mes-soignants" />
                        )}
                    </div>
                )}
            </AppLayout>
        </PageTitle>
    );
};

MedicalDetailsScreen.propTypes = {
    patientMedicals: PropTypes.arrayOf(PropTypes.object).isRequired,
    calendar: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryPatientAppointments: PropTypes.func.isRequired,
    tryPatientMedical: PropTypes.func.isRequired,
    tryPatientMedicalDelete: PropTypes.func.isRequired,
    // tryDeletePatientAppointment: PropTypes.func.isRequired,
};

const mapStateToProps = ({ medicals: { patientMedicals }, calendar: { calendar } }) => ({ patientMedicals, calendar });

const mapDispatchToProps = {
    tryPatientAppointments,
    tryPatientMedical,
    tryPatientMedicalDelete,
    // tryDeletePatientAppointment
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalDetailsScreen);
