import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    patientCreateLoading: false,
    patientCreateError: false,
    userCreated: false,

    patientValidateError: false,
    patientValidate: null,

    patientFinalizeLoading: false,
    patientFinalizeError: false,
    patientFinalized: false,

    patientCancelLoading: false,
};

function patients(state = initialState, action) {
    switch (action.type) {
        // Create patient
        case types.CREATE_PATIENT_REQUESTED: {
            return Object.assign({}, state, {
                patientCreateLoading: true
            });
        }
        case types.CREATE_PATIENT_SUCCESS: {
            return Object.assign({}, state, {
                patientCreateLoading: false,
                userCreated: true,
                patientCreateError: false
            });
        }
        case types.CREATE_PATIENT_FAILURE: {
            return Object.assign({}, state, {
                patientCreateLoading: false,
                userCreated: false,
                patientCreateError: action.data
            });
        }

        // Validate patient create
        case types.VALIDATE_PATIENT_SUCCESS: {
            return Object.assign({}, state, {
                patientValidate: action.data,
                patientValidateError: false
            });
        }
        case types.VALIDATE_PATIENT_FAILURE: {
            return Object.assign({}, state, {
                patientValidate: null,
                patientValidateError: { violations: action.data }
            });
        }

        // Cancel patient
        case types.CANCEL_PATIENT_SUCCESS: {
            return Object.assign({}, state, {
                patientValidate: null,
            });
        }
        
        default:
            return state;
    }
}

export default patients;
